import { LoadState } from "../../constants/enums";

export interface IFormulaState {
  data: IFormula[];
  totalRecords: number;
  loading: LoadState;
}

export interface IFormula {
  formula_uuid: string;
  name: string;
  formula: string;
  status: string;
  libraries: string[];
  created_by_uuid: string;
  create_ts: string;
  insert_ts?: string;
}

export const initialFormulaState: IFormulaState = {
  data: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
};

export const defaultFormula: IFormula = {
  formula_uuid: "",
  name: "",
  formula: "",
  libraries: [],
  status: "",
  created_by_uuid: "",
  create_ts: "",
};
