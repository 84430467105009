import { IQueryParams, ISearchQueryParams } from "./common.types";

export const getSearchQuery = (queryParams: IQueryParams): string => {
  const { page, rowsPerPage, status, value, columns, fromDate, toDate } =
    queryParams;
  let query = "?";
  if (status !== "-1") {
    query += `status=${status}&`;
  }
  if (columns.length > 0 && value) {
    query += `columns=${columns}&value=${value}&`;
  }
  if (fromDate) {
    query += `from_date=${fromDate}&`;
  }
  if (toDate) {
    query += `to_date=${toDate}&`;
  }
  query += `pageNo=${page}&itemPerPage=${rowsPerPage}`;
  return query;
};

export const getSearchQueryV2 = (queryParams: ISearchQueryParams): string => {
  const { page, rowsPerPage, status, value, columns, date, limit } =
    queryParams;
  let query = "";
  if (status !== "-1") {
    query += `status=${status}&`;
  }
  if (columns.length > 0 && value) {
    query += `columns=${columns}&value=${value}&`;
  }
  if (date?.fromDate) {
    query += `from_date=${date?.fromDate}&`;
  }
  if (date?.toDate) {
    query += `to_date=${date?.toDate}&`;
  }
  query += `pageNo=${page}&itemPerPage=${rowsPerPage}&pageLimit=${limit}`;
  return query;
};
