import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { EnquiryActionsType, defaultEnquiryMRF, defaultEnquiryQRF } from ".";

import {
  CLEAR_SINGLE_ENQUIRY_QRF,
  FETCH_SINGLE_ENQUIRY_QRF_FAILED,
  FETCH_SINGLE_ENQUIRY_QRF_PROGRESS,
  FETCH_SINGLE_ENQUIRY_QRF_SUCCESS,
} from "./enquiry-qrf/enquiry-qrf.actions";

import {
  CLEAR_SINGLE_ENQUIRY_MRF,
  FETCH_SINGLE_ENQUIRY_MRF_FAILED,
  FETCH_SINGLE_ENQUIRY_MRF_PROGRESS,
  FETCH_SINGLE_ENQUIRY_MRF_SUCCESS,
} from "./enquiry-mrf/enquiry-mrf.actions";

import {
  FETCH_ENQUIRY_DOCUMENTS_LIST_FAILED,
  FETCH_ENQUIRY_DOCUMENTS_LIST_PROGRESS,
  FETCH_ENQUIRY_DOCUMENTS_LIST_SUCCESS,
} from "./enquiry-documents/enquiry-documents.actions";
import { defaultEnquiry, defaultEnquiryState } from "./enquiry.state";
import {
  CLEAR_SINGLE_ENQUIRY,
  CLEAR_ENQUIRY_STATE,
  FETCH_ENQUIRY_LIST_FAILED,
  FETCH_ENQUIRY_LIST_PROGRESS,
  FETCH_ENQUIRY_LIST_SUCCESS,
  FETCH_ENQUIRY_FAILED,
  FETCH_ENQUIRY_PROGRESS,
  FETCH_ENQUIRY_SUCCESS,
} from "./enquiry.actions";
import {
  CLEAR_PROJECT,
  FETCH_PROJECT_FAILED,
  FETCH_PROJECT_LIST_FAILED,
  FETCH_PROJECT_LIST_PROGRESS,
  FETCH_PROJECT_LIST_SUCCESS,
  FETCH_PROJECT_PROGRESS,
  FETCH_PROJECT_SUCCESS,
} from "./project/project.actions";
import {
  CLEAR_ENQUIRY_HISTORY_LIST,
  FETCH_ENQUIRY_HISTORY_LIST_FAILED,
  FETCH_ENQUIRY_HISTORY_LIST_PROGRESS,
  FETCH_ENQUIRY_HISTORY_LIST_SUCCESS,
} from "./enquiry-history/enquiry-history.actions";
import {
  CLEAR_EMAIL_HISTORY,
  FETCH_EMAIL_HISTORY_FAILED,
  FETCH_EMAIL_HISTORY_PROGRESS,
  FETCH_EMAIL_HISTORY_SUCCESS,
} from "./enquiry-emails/enquiry-emails.actions";
import {
  CLEAR_ENQUIRY_CHECKLIST,
  FETCH_ENQUIRY_CHECKLIST_FAILED,
  FETCH_ENQUIRY_CHECKLIST_PROGRESS,
  FETCH_ENQUIRY_CHECKLIST_SUCCESS,
} from "./enquiry-checklist";
import {
  CLEAR_ORF_LIST,
  CLEAR_SINGLE_ORF,
  FETCH_ORF_FAILED,
  FETCH_ORF_LIST_FAILED,
  FETCH_ORF_LIST_PROGRESS,
  FETCH_ORF_LIST_SUCCESS,
  FETCH_ORF_PROGRESS,
  FETCH_ORF_SUCCESS,
} from "./enquiry-orf/enquiry-orf.actions";
import { defaultProject } from "./project";
import { defaultEnquiryORF } from "./enquiry-orf";

export const enquiryReducer = (
  state: IStoreState["enquiries"] = defaultEnquiryState,
  action: EnquiryActionsType,
) => {
  switch (action.type) {
    // ###################################################################################
    // ################################# Project Reducer #################################
    // ###################################################################################
    case FETCH_PROJECT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.project_list.loading = LoadState.InProgress;
        draftState.project_list.data = [];
      });
      return newState;
    }
    case FETCH_PROJECT_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.project_list.loading = LoadState.Loaded;
        draftState.project_list.data = list;
        draftState.project_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PROJECT_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.project_list.loading = LoadState.InProgress;
        draftState.project_list.data = [];
        draftState.project_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PROJECT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.project.loading = LoadState.InProgress;
        draftState.project.data = defaultProject;
      });
      return newState;
    }
    case FETCH_PROJECT_SUCCESS: {
      const { project } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.project.loading = LoadState.Loaded;
        draftState.project.data = project;
      });
      return newState;
    }
    case FETCH_PROJECT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.project.loading = LoadState.Failed;
        draftState.project.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_PROJECT: {
      const newState = produce(state, (draftState) => {
        draftState.project.loading = LoadState.NotLoaded;
        draftState.project.data = defaultProject;
        draftState.project.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ################################# Enquiry Reducer #################################
    // ###################################################################################
    case FETCH_ENQUIRY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_list.loading = LoadState.InProgress;
        draftState.enquiry_list.data = [];
        draftState.enquiry_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ENQUIRY_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_list.loading = LoadState.Loaded;
        draftState.enquiry_list.data = list;
        draftState.enquiry_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ENQUIRY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_list.loading = LoadState.InProgress;
        draftState.enquiry_list.data = [];
        draftState.enquiry_list.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_ENQUIRY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_enquiry.loading = LoadState.InProgress;
        draftState.single_enquiry.data = defaultEnquiry;
      });
      return newState;
    }
    case FETCH_ENQUIRY_SUCCESS: {
      const { lead } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_enquiry.loading = LoadState.Loaded;
        draftState.single_enquiry.data = lead;
        draftState.enquiryStatus = lead.status;
      });
      return newState;
    }
    case FETCH_ENQUIRY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_enquiry.loading = LoadState.Failed;
        draftState.single_enquiry.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_ENQUIRY: {
      const newState = produce(state, (draftState) => {
        draftState.single_enquiry.loading = LoadState.NotLoaded;
        draftState.single_enquiry.data = defaultEnquiry;
        draftState.single_enquiry.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################# Enquiry History Reducer #############################
    // ###################################################################################

    case FETCH_ENQUIRY_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_history.loading = LoadState.InProgress;
        draftState.enquiry_history.data = [];
      });
      return newState;
    }
    case FETCH_ENQUIRY_HISTORY_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_history.loading = LoadState.Loaded;
        draftState.enquiry_history.data = list;
        draftState.enquiry_history.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ENQUIRY_HISTORY_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_history.loading = LoadState.Failed;
        draftState.enquiry_history.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_ENQUIRY_HISTORY_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_history.loading = LoadState.NotLoaded;
        draftState.enquiry_history.data = [];
        draftState.enquiry_history.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################ Enquiry Documents Reducer ############################
    // ###################################################################################

    case FETCH_ENQUIRY_DOCUMENTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_documents.loading = LoadState.NotLoaded;
        draftState.enquiry_documents.data = [];
        draftState.enquiry_documents.error = null;
      });
      return newState;
    }
    case FETCH_ENQUIRY_DOCUMENTS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_documents.loading = LoadState.Loaded;
        draftState.enquiry_documents.data = data;
        draftState.enquiry_documents.error = null;
      });
      return newState;
    }

    case FETCH_ENQUIRY_DOCUMENTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_documents.loading = LoadState.Failed;
      });
      return newState;
    }

    // ###################################################################################
    // ########################## Enquiry Email History Reducer ##########################
    // ###################################################################################

    case FETCH_EMAIL_HISTORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_emails.loading = LoadState.InProgress;
        draftState.enquiry_emails.data = [];
      });
      return newState;
    }
    case FETCH_EMAIL_HISTORY_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_emails.loading = LoadState.Loaded;
        draftState.enquiry_emails.data = list;
        draftState.enquiry_emails.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_EMAIL_HISTORY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_emails.loading = LoadState.Failed;
        draftState.enquiry_emails.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_EMAIL_HISTORY: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_emails.loading = LoadState.NotLoaded;
        draftState.enquiry_emails.data = [];
        draftState.enquiry_emails.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################ Enquiry CheckList Reducer ############################
    // ###################################################################################

    case FETCH_ENQUIRY_CHECKLIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_checkList.loading = LoadState.InProgress;
        draftState.enquiry_checkList.data = [];
      });
      return newState;
    }
    case FETCH_ENQUIRY_CHECKLIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_checkList.loading = LoadState.Loaded;
        draftState.enquiry_checkList.data = list;
      });
      return newState;
    }
    case FETCH_ENQUIRY_CHECKLIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_checkList.loading = LoadState.Failed;
        draftState.enquiry_checkList.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_ENQUIRY_CHECKLIST: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_checkList.loading = LoadState.NotLoaded;
        draftState.enquiry_checkList.data = [];
        draftState.enquiry_checkList.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################### Enquiry ORF Reducer ###############################
    // ###################################################################################

    case FETCH_ORF_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf_ist.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ORF_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf_ist.loading = LoadState.Loaded;
        draftState.enquiry_orf_ist.data = data;
        draftState.enquiry_orf_ist.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ORF_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf_ist.loading = LoadState.Failed;
        draftState.enquiry_orf_ist.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_ORF_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf_ist.loading = LoadState.NotLoaded;
        draftState.enquiry_orf_ist.data = [];
        draftState.enquiry_orf_ist.error = null;
      });
      return newState;
    }

    case FETCH_ORF_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf.orfLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ORF_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf.orfLoading = LoadState.Loaded;
        draftState.enquiry_orf.orfData = data;
      });
      return newState;
    }
    case FETCH_ORF_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf.orfLoading = LoadState.Failed;
        draftState.enquiry_orf.orfError = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_ORF: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_orf.orfLoading = LoadState.NotLoaded;
        draftState.enquiry_orf.orfData = defaultEnquiryORF;
        draftState.enquiry_orf.orfError = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################### Enquiry QRF Reducer ###############################
    // ###################################################################################

    case FETCH_SINGLE_ENQUIRY_QRF_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_qrf.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_ENQUIRY_QRF_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_qrf.loading = LoadState.Loaded;
        draftState.enquiry_qrf.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_ENQUIRY_QRF_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_qrf.loading = LoadState.Failed;
        draftState.enquiry_qrf.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_ENQUIRY_QRF: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_qrf.loading = LoadState.NotLoaded;
        draftState.enquiry_qrf.data = defaultEnquiryQRF;
        draftState.enquiry_qrf.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################### Enquiry MRF Reducer ###############################
    // ###################################################################################

    case FETCH_SINGLE_ENQUIRY_MRF_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_mrf.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_ENQUIRY_MRF_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_mrf.loading = LoadState.Loaded;
        draftState.enquiry_mrf.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_ENQUIRY_MRF_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.enquiry_mrf.loading = LoadState.Failed;
        draftState.enquiry_mrf.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_ENQUIRY_MRF: {
      const newState = produce(state, (draftState) => {
        draftState.enquiry_mrf.loading = LoadState.NotLoaded;
        draftState.enquiry_mrf.data = defaultEnquiryMRF;
        draftState.enquiry_mrf.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ################################### Clear State ###################################
    // ###################################################################################
    case CLEAR_ENQUIRY_STATE: {
      return defaultEnquiryState;
    }

    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
