import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../constants/enums";

const ProjectsTables = Loadable(
  lazy(() =>
    import("./ProjectsTable").then(({ ProjectsTable }) => ({
      default: ProjectsTable,
    })),
  ),
);
const ManageSingleProject = Loadable(
  lazy(() =>
    import("./ManageProjects").then(({ ManageSingleProject }) => ({
      default: ManageSingleProject,
    })),
  ),
);

export const projectsRoutes = [
  {
    path: "/sales/projects",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ProjectsTables />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/sales/projects/manage",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleProject />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/sales/projects/manage/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleProject />
      </AuthorizedRoute>
    ),
  },
];
