import { combineReducers } from "redux";

import { IStoreState } from "./initialStoreState";
import { messageReducer } from "./messages/messagesReducer";
import { customerReducer } from "./customer/customerReducer";

import { commonReducer } from "./common/commonReducer";

import { UserProfileReducer } from "./UserProfileList/userProfileReducer";

import { opportunitesReducer } from "./opportunites/opportunitesReducer";
import { enquiryReducer } from "./enquiries/enquiry.reducer";
import { customizerReducer } from "./customizer/customizerReducer";
import { companyReducer } from "./company/companyReducer";
import { contactsReducer } from "./contacts/contactsReducer";
import { productsReducer } from "./products/product.reducer";
import { quotesReducer } from "./quote/quoteReducer";
import { tasksBoardReducer } from "./taskBoard/taskBoardReducer";
import { securityReducer } from "./security/securityReducer";
import { renderingReducer } from "./rendering/renderingReducer";
import { dataManagementReducer } from "./DataManagement/dataManagementReducer";
import { costingSheetReducer } from "./costingSheet";
import { quotesApprovalReducer } from "./approval/quote";
import { commentReducer } from "./comments/comment.reducer";
import { orderProcessingReducer } from "./sales/salesOrder/orderProcessing.reducer";
import { aprovalsReducer } from "./approval/approvals/approvalsReducer";
import { bomReducer } from "./bom";
import { drawingReducer } from "./drawing";
import { deviationReducer } from "./deviation";
import { ecnReducer } from "./ecn";
import { approvalItemReducer } from "./approval/productExpiry";
import { ncpReducer } from "./ncp";
import { workOrderReducer } from "./workOrder";
import { drawingUploadsReducer } from "./drawing-uploads";
import { partItemReducer } from "./partItem/partItemReducer";
import { DrawingItemReducer } from "./drawingItem";
import { masterBomReducer } from "./masterBom";
import { mrpAllocationsReducer } from "./mrp-allocations";
import { routingReducer } from "./routing";
import { vendorsReducer } from "./vendors";
import { purchaseRequisitionReducer } from "./purchase-requisition";
import { purchaseOrderReducer } from "./purchase-order";
import { warehouseReducer } from "./warehouse";
import { categoryReducer } from "./cateogory/categoryReducer";
import { webProductReducer } from "./webProduct";
import { expressionTransformationReducer } from "./expressionTransformation/expressionTransformationReducer";
import { requestReducer } from "./request";
import { BdlReducer } from "./bdl";
import { internalOrdersReducer } from "./internal-orders";
import { formulaReducer } from "./formula/formulaReducer";
import { materialRequestReducer } from "./material-request";

const appReducer = combineReducers({
  customer: customerReducer,
  userprofile: UserProfileReducer,
  opportunites: opportunitesReducer,
  customizerReducer: customizerReducer,
  enquiries: enquiryReducer,
  company: companyReducer,
  contacts: contactsReducer,
  products: productsReducer,
  category: categoryReducer,
  bom: bomReducer,
  routing: routingReducer,
  masterBom: masterBomReducer,
  drawing: drawingReducer,
  ecn: ecnReducer,
  ncp: ncpReducer,
  partItem: partItemReducer,
  drawingItem: DrawingItemReducer,
  deviation: deviationReducer,
  message: messageReducer,
  common: commonReducer,
  quotes: quotesReducer,
  costingSheet: costingSheetReducer,
  taskBoard: tasksBoardReducer,
  security: securityReducer,
  rendering: renderingReducer,
  dataManagement: dataManagementReducer,
  approvalQuotes: quotesApprovalReducer,
  approvalItem: approvalItemReducer,
  comment: commentReducer,
  orderProcessing: orderProcessingReducer,
  approvals: aprovalsReducer,
  workOrder: workOrderReducer,
  drawingUploads: drawingUploadsReducer,
  mrp_allocations: mrpAllocationsReducer,
  vendors: vendorsReducer,
  purchaseRequisition: purchaseRequisitionReducer,
  purchaseOrder: purchaseOrderReducer,
  warehouse: warehouseReducer,
  webProduct: webProductReducer,
  expressionTransformation: expressionTransformationReducer,
  request: requestReducer,
  bdl: BdlReducer,
  internalOrders: internalOrdersReducer,
  formula: formulaReducer,
  material_request: materialRequestReducer,
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
