import produce from "immer";
import { FormulaActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialFormulaState } from "./formula.types";
import {
  FETCH_FORMULA_FAILED,
  FETCH_FORMULA_PROGRESS,
  FETCH_FORMULA_SUCCESS,
} from "./formulaActions";
import { LoadState } from "../../constants/enums";

export const formulaReducer = (
  state: IStoreState["formula"] = initialFormulaState,
  action: FormulaActions,
) => {
  switch (action.type) {
    case FETCH_FORMULA_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.data = [];
        draftState.loading = LoadState.InProgress;
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_FORMULA_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.data = data;
        draftState.loading = LoadState.Loaded;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_FORMULA_FAILED: {
      //const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.data = [];
        draftState.loading = LoadState.Failed;
        draftState.totalRecords = 0;
      });
      return newState;
    }

    default:
      return state;
  }
};
