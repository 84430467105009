import { LoadState } from "../../constants/enums";
import { IBom, IBomItem, IBomProduct, IBomState } from "./bom.types";

export const defaultBomItem: IBomItem = {
  bom_item_uuid: null,
  level: "",
  bom_item_code: "",
  bom_item_name: "",
  drawing_uuid: "",
  drawing_name: "",
  drawing_no: "",
  parent_child: "",
  root: null,
  parent: null,
  status: "ACTIVE",
};

export const defaultBomProduct: IBomProduct = {
  bom_item_uuid: null,
  level: "",
  part_code: "",
  drawing_uuid: "",
  drawing_name: "",
  drawing_no: "",
  part_name: "",
  product_h: 0,
  product_w: 0,
  product_d: 0,
  meaning: "",
  item_type: "",
  quantity_per_unit: 0,
  unit: "",
};
export const initialBom: IBom = {
  bom_uuid: "",
  product_code: "",
  order_no: "",
  assigned_to_uuid: null,
  assigned_to_name: null,
  product_size: null,
  series: null,
  rev_no: null,
  effective_date: null,
  description: null,
  add: null,
  change: null,
  delete: null,
  prepared_by_uuid: null,
  prepared_by_name: null,
  checked_by_uuid: null,
  checked_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  excel_link: null,
  approval_status: null,
  status: "BOM_REQUESTED",
  product_items: [defaultBomProduct],
  master_bom_reference: [{ key: "", value: "" }],
};

export const initialBomState: IBomState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  bom: {
    data: initialBom,
    loading: LoadState.NotLoaded,
    error: null,
  },
  approvalBomList: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  bomItem: {
    data: defaultBomItem,
    loading: LoadState.NotLoaded,
    error: null,
  },
  order_existing_bom: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
