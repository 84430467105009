import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { IFormula } from "./formula.types";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";

export const FETCH_FORMULA_PROGRESS = "FETCH_FORMULA_PROGRESS";
export const FETCH_FORMULA_SUCCESS = "FETCH_FORMULA_SUCCESS";
export const FETCH_FORMULA_FAILED = "FETCH_FORMULA_FAILED";

export const fetchFormulaProgress = () => action(FETCH_FORMULA_PROGRESS);
export const fetchFormulaSuccess = (data: IFormula[], totalRecords: number) =>
  action(FETCH_FORMULA_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchFormulaFailed = () => action(FETCH_FORMULA_FAILED);

export const fetchFormulasync =
  (
    pageNumber: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchFormulaProgress());
      const res = await api.get(
        `/formula/get-formula?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
      );
      const data: IFormula[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchFormulaSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchFormulaFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
