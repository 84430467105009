import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  fetchProjectProgress,
  fetchProjectSuccess,
} from "./project/project.actions";
import { IProject } from "./project/project.types";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IEnquiry, IPostEnquiry } from "./enquiry.types";

// --------------- Lead Actions----------------------

export const FETCH_ENQUIRY_LIST_PROGRESS = "FETCH_ENQUIRY_LIST_PROGRESS";
export const FETCH_ENQUIRY_LIST_SUCCESS = "FETCH_ENQUIRY_LIST_SUCCESS";
export const FETCH_ENQUIRY_LIST_FAILED = "FETCH_ENQUIRY_LIST_FAILED";

export const fetchEnquiryListProgress = () =>
  action(FETCH_ENQUIRY_LIST_PROGRESS);
export const fetchEnquiryListSuccess = (
  list: IEnquiry[],
  totalRecords: number,
) => action(FETCH_ENQUIRY_LIST_SUCCESS, { list, totalRecords });
export const fetchEnquiryListFailed = () => action(FETCH_ENQUIRY_LIST_FAILED);

export const fetchEnquiryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchEnquiryListProgress());
      const res = await api.get(`/enquiry/get-enquiry${searchQuery}`);
      const data: IEnquiry[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEnquiryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEnquiryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchProjectEnquiriesListAsync =
  (
    queryParams: IQueryParams,
    projectUUID: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchEnquiryListProgress());
      const res = await api.get(
        `/enquiry/get-enquiry${searchQuery}&project_uuid=${projectUUID}`,
      );
      const data: IEnquiry[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEnquiryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEnquiryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchEnquiryOrdersAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchEnquiryListProgress());
      const res = await api.get(`/enquiry/get-orders${searchQuery}`);
      const data: IEnquiry[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEnquiryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEnquiryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_ENQUIRY_PROGRESS = "FETCH_ENQUIRY_PROGRESS";
export const FETCH_ENQUIRY_SUCCESS = "FETCH_ENQUIRY_SUCCESS";
export const FETCH_ENQUIRY_FAILED = "FETCH_ENQUIRY_FAILED";

export const fetchEnquiryProgress = () => action(FETCH_ENQUIRY_PROGRESS);
export const fetchEnquirySuccess = (lead: IEnquiry) =>
  action(FETCH_ENQUIRY_SUCCESS, { lead });
export const fetchEnquiryFailed = (errorMessage: string) =>
  action(FETCH_ENQUIRY_FAILED, { errorMessage });

export const fetchEnquiryByLeadCodeAsync =
  (leadCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnquiryProgress());
      dispatch(fetchProjectProgress());
      const res = await api.get(`/enquiry/get-enquiry?enquiry_id=${leadCode}`);
      const data = res.data.data;
      if (data.length > 0) {
        const leadData: IEnquiry = data[0] as IEnquiry;
        const projectData: IProject = data[0] as IProject;
        dispatch(fetchEnquirySuccess(leadData));
        dispatch(fetchProjectSuccess(projectData));
      } else {
        dispatch(
          fetchEnquiryFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchEnquiryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchLatestEnquiryListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnquiryListProgress());

      let finalUrl = `/enquiry/get-enquiry?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`;

      const res = await api.get(finalUrl);
      const data: IEnquiry[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEnquiryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEnquiryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchSingleEnquiryAsync =
  (leadId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnquiryProgress());
      dispatch(fetchProjectProgress());
      const res = await api.get(`/enquiry/get-enquiry?enquiry_uuid=${leadId}`);
      const data = res.data.data;
      if (data.length > 0) {
        const leadData: IEnquiry = data[0] as IEnquiry;
        const projectData: IProject = data[0] as IProject;
        dispatch(fetchEnquirySuccess(leadData));
        dispatch(fetchProjectSuccess(projectData));
      } else {
        dispatch(
          fetchEnquiryFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchEnquiryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchEnquiryOrderInfoAsync =
  (order_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnquiryProgress());
      dispatch(fetchProjectProgress());
      const res = await api.get(`/enquiry/get-orders?order_uuid=${order_uuid}`);
      const data = res.data.data;
      if (data.length > 0) {
        const leadData: IEnquiry = data[0] as IEnquiry;
        const projectData: IProject = data[0] as IProject;
        dispatch(fetchEnquirySuccess(leadData));
        dispatch(fetchProjectSuccess(projectData));
      } else {
        dispatch(
          fetchEnquiryFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchEnquiryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleEnquiryAsync =
  (
    lead: IEnquiry,
    file: any | null,
    isopportunity: boolean,
    onCallback: (
      isSuccess: boolean,
      isopportunity?: boolean,
      enquiry_uuid?: string | null,
      enquiry_no?: string | null,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const leadPayload: IPostEnquiry = {
        enquiry_uuid: lead.enquiry_uuid,
        project_uuid: lead.project_uuid,
        design_coordinator_uuid: lead.design_coordinator_uuid,
        design_coordinator_name: lead.design_coordinator_name,
        // project_name: lead.project_name,

        assigned_to_name: lead.assigned_to_name,
        secondary_assigned_to_name: lead.secondary_assigned_to_name,

        sales_person: lead.sales_person,
        designer: lead.designer,
        industry: lead.industry,

        customer_uuid: lead.customer_uuid,
        customer_name: lead.customer_name,

        contact_uuid: lead.contact_uuid,
        contact_person: lead.contact_person,
        contact_email: lead.contact_email,

        order_type: lead.order_type,
        order_approx_value: lead.order_approx_value,
        // design_cordinator: lead.design_cordinator,
        // date_of_closure: lead.date_of_closure,
        probability: lead.probability,
        probability_status: lead.probability_status,

        product_category: lead.product_category,
        product_type: lead.product_type,
        appx_no_of_workstation: lead.appx_no_of_workstation,
        enquiry_details: lead.enquiry_details,
        remarks: lead.remarks,
        win_loss: lead.win_loss,
        reason_for_loss: lead.reason_for_loss,
        // design_attachments: lead.design_attachments,
        assigned_to_uuid: lead.assigned_to_uuid,
        secondary_assigned_to_uuid: lead.secondary_assigned_to_uuid,

        customer_address_line1: lead.customer_address_line1,
        customer_address_line2: lead.customer_address_line2,
        customer_address_state: lead.customer_address_state,
        customer_address_city: lead.customer_address_city,
        customer_address_district: lead.customer_address_district,
        customer_address_pincode: lead.customer_address_pincode,
        customer_address_country: lead.customer_address_country,
        status: lead.status,
        communication_status: lead.communication_status,

        //pi
        proforma_invoice_uuid: lead.proforma_invoice_uuid,

        //delivery address should include in upsert
        delivery_address_line1: lead.delivery_address_line1,
        delivery_address_line2: lead.delivery_address_line2,
        delivery_address_city: lead.delivery_address_city,
        delivery_address_district: lead.delivery_address_district,
        delivery_address_state: lead.delivery_address_state,
        delivery_address_pincode: lead.delivery_address_pincode,
        delivery_address_country: lead.delivery_address_country,
        created_by_name: lead.created_by_name,
        created_by_uuid: lead.created_by_uuid,
        modified_by_name: lead.modified_by_name,
        modified_by_uuid: lead.modified_by_uuid,
      };

      const {
        proforma_invoice_uuid,
        delivery_address_line1,
        delivery_address_line2,
        delivery_address_city,
        delivery_address_district,
        delivery_address_state,
        delivery_address_pincode,
        delivery_address_country,
        created_by_name,
        created_by_uuid,
        modified_by_name,
        modified_by_uuid,

        approval_uuid,
        ...rest
      } = leadPayload;
      // const asPayload = {
      //   "enquiry_no": lead.enquiry_no,
      // };
      // const path = await uploadFile(
      //   file,
      //   "ENQUIRY",
      //   lead.design_attachment || "",
      //   asPayload,
      // );
      // const payload = {
      //   ...leadPayload,
      //   proforma_invoice_uuid: lead.proforma_invoice_uuid
      // };
      await api.post("/enquiry/upsert-enquiry", rest);
      let message = "Enquiry is saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true, isopportunity, lead.quote_uuid, lead.enquiry_no);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

/***------------ */

export const quoteRevisionAsync =
  (
    data: { enquiry_no: string; quote_uuid: string },
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/quotes/quote-revision", data);

      dispatch(saveLoaderCompleted());
      dispatch(
        showMessage({
          type: "success",
          message: "Quote  sent for revision!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const requestOrderAsync =
  (
    data: { enquiry_no: string },
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.get(
        "/enquiry/raise-order-approval-request?enquiry_no=" + data.enquiry_no,
      );

      dispatch(saveLoaderCompleted());
      dispatch(
        showMessage({
          type: "success",
          message: "Order request successfully submitted!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ENQUIRY_STATE = "CLEAR_ENQUIRY_STATE";
export const CLEAR_SINGLE_ENQUIRY = "CLEAR_SINGLE_ENQUIRY";

export const clearEnquiryState = () => action(CLEAR_ENQUIRY_STATE);
export const clearSingleEnquiry = () => action(CLEAR_SINGLE_ENQUIRY);
