import { api } from "../api/api";
import { IBomProduct } from "../redux/bom/bom.types";

export const validateBomInterpreteFormula = async (
  formula: string,
  productItems: IBomProduct[],
  libraries: string[],
) => {
  const res = await api.post("/general/interprete-fomrula", {
    eval_string: formula,
    libraries: libraries,
    external_variables: {
      product_items: {
        ...productItems,
      },
    },
  });

  return res.data.data.data;
};
