import { ISelectOption } from "../../constants/types";

export const QUOTES_ROUTE = "/sales/quotes";

export const quote_payment_mode: ISelectOption[] = [
  { label: "DOLLAR", value: "DOLLAR" },
  { label: "RUPEES", value: "RUPEES" },
];

export const quote_origin_from: ISelectOption[] = [
  { label: "Sri City", value: "Sri City" },
  { label: "Bangalore", value: "Bangalore" },
];

export const quotation_type = {
  RWSR_PI: "RWSR-PI",
  RWSR_FOC_PI: "RWSR-FOC-PI",
  RWSR_TRN: "RWSR-TRN",
  RWBR_PI: "RWBR-PI",
  RWBR_FOC_PI: "RWBR-FOC-PI",
  RWBR_TRN: "RWBR-TRN",
};
export type IQuotationType = keyof typeof quotation_type;
export const quote_quotation_type: ISelectOption[] = [
  { label: "RWSR-PI", value: quotation_type.RWSR_PI },
  { label: "RWSR-FOC-PI", value: quotation_type.RWSR_FOC_PI },
  { label: "RWSR-TRN", value: quotation_type.RWSR_TRN },
];
export const quote_quotation_type_bengluru: ISelectOption[] = [
  { label: "RWBR-PI", value: quotation_type.RWBR_PI },
  { label: "RWBR-FOC-PI", value: quotation_type.RWBR_FOC_PI },
  { label: "RWBR-TRN", value: quotation_type.RWBR_TRN },
];

export const quote_advising_banks: ISelectOption[] = [
  { label: "AXIS BANK FOR INR", value: "AXIS BANK FOR INR" },
  { label: "AXIS BANK FOR USD", value: "AXIS BANK FOR USD" },
];

export const quote_inco: ISelectOption[] = [
  { label: "FOB", value: "FOB" },
  { label: "CIF", value: "CIF" },
  { label: "FOC", value: "FOC" },
  { label: "EX-WORKS", value: "EX-WORKS" },
];
export const quote_area: ISelectOption[] = [
  { label: "DTZ", value: "DTZ" },
  { label: "EOU", value: "EOU" },
  { label: "SEZ", value: "SEZ" },
  { label: "SFIS", value: "SFIS" },
  { label: "STPI", value: "STPI" },
];
