import React from "react";
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Chip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IBreadcrumbProps } from "./IBreadcrumbProps";
import { ArrowBack } from "@mui/icons-material";
import styled from "@emotion/styled";
import { CustomChip } from "../../../components/CustomChip";

export const Breadcrumb: React.FC<IBreadcrumbProps> = ({
  subtitle,
  items,
  values,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const {
    enquiryNumber,
    uuid: enquiryUUID,
    tab: currentTab,
    order_uuid,
  } = useParams() as {
    enquiryNumber?: string;
    uuid?: string | null;
    order_uuid?: string | null;
    tab?: string | null;
  };

  return (
    <Box display="flex" sx={{ mb: 2, alignItems: "center" }}>
      {/* Back Arrow */}
      <Box
        sx={(theme) => ({
          pl: 1,
          pr: 1,
          mr: 1,
          [theme.breakpoints.down("sm")]: { mt: 6 },
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <ArrowBack
          color="action"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
      </Box>

      {/* Breadcrumb and Subtitle */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography color="textSecondary" fontWeight="400" variant="h4">
          {subtitle}
        </Typography>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: { mt: 6 },
          })}
        >
          {items?.map((item) => (
            <div key={item.title}>
              {item.to ? (
                <StyledBreadcrumb
                  component="a"
                  href={item.to}
                  label={item.title}
                />
              ) : (
                <Typography color="textPrimary" textTransform="capitalize">
                  {item.title}
                </Typography>
              )}
            </div>
          ))}
        </Breadcrumbs>
      </Box>

      {/* Custom Chip */}
      {enquiryNumber && values && (
        <Box
          sx={{
            ml: isDesktop ? "auto" : 0,
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            alignItems: isDesktop ? "center" : "flex-start",
            gap: isDesktop ? 0 : 1,
            mt: isDesktop ? 0 : 2,
          }}
        >
          {isDesktop ? (
            <CustomChip
              size="medium"
              color="primary"
              content={values}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                backgroundColor: "primary.main",
                color: "#fff",
                fontWeight: 600,
                paddingX: 2,
                paddingY: 1,
                borderRadius: 1,
              }}
            >
              <Typography>Enquiry No.: {values}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const StyledBreadcrumb = styled(Chip)(({ theme }: any) => ({
  backgroundColor: theme.palette.primary.main,
  height: theme.spacing(3),
  color: "#fff",
  mt: 3,
  fontWeight: theme.typography.fontWeightRegular,
})) as typeof Chip;
