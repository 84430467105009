import produce from "immer";
import { ExpressionTransformationActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialExpressionTransformation } from "./expressionTransformation.types";
import {
  FETCH_EXPRESSION_COLUMNS_FAILED,
  FETCH_EXPRESSION_COLUMNS_PROGRESS,
  FETCH_EXPRESSION_COLUMNS_SUCCESS,
  FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_FAILED,
  FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_PROGRESS,
  FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_SUCCESS,
  FETCH_EXPRESSION_FUNCTIONS_FAILED,
  FETCH_EXPRESSION_FUNCTIONS_PROGRESS,
  FETCH_EXPRESSION_FUNCTIONS_SUCCESS,
  FETCH_EXPRESSION_MAPPINGS_FAILED,
  FETCH_EXPRESSION_MAPPINGS_PROGRESS,
  FETCH_EXPRESSION_MAPPINGS_SUCCESS,
} from "./expressionTransformationActions";
import { LoadState } from "../../constants/enums";

export const expressionTransformationReducer = (
  state: IStoreState["expressionTransformation"] = initialExpressionTransformation,
  action: ExpressionTransformationActions,
) => {
  switch (action.type) {
    case FETCH_EXPRESSION_FUNCTIONS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.funsVars.data = {};
        draftState.funsVars.loading = LoadState.InProgress;
        draftState.funsVars.error = null;
      });
      return newState;
    }
    case FETCH_EXPRESSION_FUNCTIONS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.funsVars.data = data;
        draftState.funsVars.loading = LoadState.Loaded;
        draftState.funsVars.error = null;
      });
      return newState;
    }
    case FETCH_EXPRESSION_FUNCTIONS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.funsVars.data = {};
        draftState.funsVars.loading = LoadState.Failed;
        draftState.funsVars.error = errorMessage;
      });
      return newState;
    }

    case FETCH_EXPRESSION_MAPPINGS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.mappings.data = [];
        draftState.mappings.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_EXPRESSION_MAPPINGS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.mappings.data = data;

        draftState.mappings.loading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_EXPRESSION_MAPPINGS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.mappings.data = [];
        draftState.mappings.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_EXPRESSION_COLUMNS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.variables.data = [];
        draftState.variables.loading = LoadState.InProgress;
        draftState.variables.error = null;
      });
      return newState;
    }
    case FETCH_EXPRESSION_COLUMNS_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.variables.data = data;
        draftState.variables.loading = LoadState.Loaded;
        draftState.variables.totalRecords = totalRecords;
        draftState.variables.error = null;
      });
      return newState;
    }
    case FETCH_EXPRESSION_COLUMNS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.variables.data = [];
        draftState.variables.loading = LoadState.Failed;
        draftState.variables.error = errorMessage;
      });
      return newState;
    }

    case FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.externalFunctions.data = {};
        draftState.externalFunctions.loading = LoadState.InProgress;
        draftState.externalFunctions.error = null;
      });
      return newState;
    }
    case FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.externalFunctions.data = data;
        draftState.externalFunctions.loading = LoadState.Loaded;
        draftState.externalFunctions.error = null;
      });
      return newState;
    }
    case FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.externalFunctions.data = {};
        draftState.externalFunctions.loading = LoadState.Failed;
        draftState.externalFunctions.error = errorMessage;
      });
      return newState;
    }

    default:
      return state;
  }
};
